// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-5-k-may-tsx": () => import("./../../../src/pages/5k-may.tsx" /* webpackChunkName: "component---src-pages-5-k-may-tsx" */),
  "component---src-pages-about-us-our-charity-tsx": () => import("./../../../src/pages/about-us/our-charity.tsx" /* webpackChunkName: "component---src-pages-about-us-our-charity-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-barrecore-tsx": () => import("./../../../src/pages/barrecore.tsx" /* webpackChunkName: "component---src-pages-barrecore-tsx" */),
  "component---src-pages-boom-cycle-tsx": () => import("./../../../src/pages/boom-cycle.tsx" /* webpackChunkName: "component---src-pages-boom-cycle-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kobox-tsx": () => import("./../../../src/pages/kobox.tsx" /* webpackChunkName: "component---src-pages-kobox-tsx" */),
  "component---src-pages-our-partners-charity-tsx": () => import("./../../../src/pages/our-partners/charity.tsx" /* webpackChunkName: "component---src-pages-our-partners-charity-tsx" */),
  "component---src-pages-our-partners-corporate-tsx": () => import("./../../../src/pages/our-partners/corporate.tsx" /* webpackChunkName: "component---src-pages-our-partners-corporate-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */)
}

